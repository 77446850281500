'use strict'

import EL from '../constant/elements'
import getDeviceType from '../helper/getDeviceType'
import toBoolean from '../helper/toBoolean'
import { throttle, debounce } from 'throttle-debounce'
import { gsap, Power3 } from 'gsap'

/**
 * accordion処理を提供します
 */
export default () => {
  const func = {
    deviceType: getDeviceType(),
    btn: document.querySelectorAll('.js-nav-accordion-btn'),

    /**
     * init
     */
    init: () => {
      // addEventListener btn(.js-nav-accordion)
      for (let i = 0; i < func.btn.length; i = (i + 1) | 0) {
        const btn = func.btn[i]

        // click event
        btn.addEventListener('click', () => {
          const parent = btn.parentNode

          if (!btn.classList.contains('is-open')) {
            func.show(parent, btn, true)
          } else {
            func.hide(parent, btn, false)
          }
        })
      }

      // resize event listener
      window.addEventListener('resize', throttle(150, func.resize), false)
    },

    /**
     * show
     */
    show: (parent, btn, state) => {
      const subitem = parent.querySelector('.js-nav-accordion-wrap')
      btn.classList.add('is-open')

      const to = {
        height: 'auto',
        opacity: 1,
        ease: Power3.easeInOut,
        visibility: 'visible'
      }
      gsap.to(subitem, 0.4, to)
    },

    /**
     * hide
     */
    hide: (parent, btn, state) => {
      const subitem = parent.querySelector('.js-nav-accordion-wrap')
      btn.classList.remove('is-open')

      const to = {
        height: 0,
        opacity: 0,
        ease: Power3.easeInOut,
        onComplete: () => {
          gsap.set(subitem, {
            visibility: 'hidden'
          })
        }
      }
      gsap.to(subitem, 0.4, to)
    },

    /**
     * resize
     */
    resize: () => {
      // deviceType切り替え時のみ
      if (func.deviceType !== getDeviceType()) {
        func.deviceType = getDeviceType()

        // data-initialopenに沿ってアコーディオンの状態を初期化
        for (let i = 0; i < func.btn.length; i = (i + 1) | 0) {
          const btn = func.btn[i]
          const parent = btn.parentNode

          if (typeof btn.dataset.initialopen !== 'undefined') {
            if (toBoolean(btn.dataset.initialopen) && !btn.classList.contains('is-open')) {
              func.show(parent, btn, true)
            }

            if (!toBoolean(btn.dataset.initialopen) && btn.classList.contains('is-open')) {
              func.hide(parent, btn, true)
            }
          }
        }
      }
    }
  }

  func.init()
}
