'use strict'
import getDeviceType from '../helper/getDeviceType'
import Swiper from 'swiper/bundle'

export default () => {
  let deviceType = getDeviceType()
  let charterSlider01
  let charterSlider02
  let charterSlider03

  const initCharterSlider = () => {
    charterSlider01 = new Swiper('.js-charter-slider01', {
      init: true,
      slidesPerView: 1,
      spaceBetween: 0,
      centeredSlides: true,
      observer: true,
      observeParents: true,
      autoplay: {
        delay: 6000
      },
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      },
      navigation: {
        nextEl: '.swiper-button-next01',
        prevEl: '.swiper-button-prev01'
      }
    })

    charterSlider02 = new Swiper('.js-charter-slider02', {
      init: true,
      slidesPerView: 1,
      spaceBetween: 0,
      centeredSlides: true,
      observer: true,
      observeParents: true,
      autoplay: {
        delay: 6000
      },
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      },
      navigation: {
        nextEl: '.swiper-button-next02',
        prevEl: '.swiper-button-prev02'
      }
    })

    charterSlider03 = new Swiper('.js-charter-slider03', {
      init: true,
      slidesPerView: 1,
      spaceBetween: 0,
      centeredSlides: true,
      observer: true,
      observeParents: true,
      autoplay: {
        delay: 6000
      },
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      },
      navigation: {
        nextEl: '.swiper-button-next03',
        prevEl: '.swiper-button-prev03'
      }
    })
  }

  initCharterSlider()

  $(window).on('resize', () => {
    if (deviceType !== getDeviceType()) {
      deviceType = getDeviceType()
      charterSlider01.destroy(true, true)
      charterSlider02.destroy(true, true)
      charterSlider03.destroy(true, true)
      initCharterSlider()
    }
  })
}
