'use strict'

// よく使用するElements
export default {
  HTML: document.getElementsByTagName('html')[0],
  BODY: document.getElementsByTagName('body')[0],
  HEADER: document.getElementsByTagName('header')[0],
  MAIN: document.getElementsByTagName('main')[0],
  FOOTER: document.getElementsByTagName('footer')[0],
  COPYRIGHT: document.querySelector('.l-footer__copyright'),
  NAV: document.querySelectorAll('.l-nav__link'),
  HMB: document.querySelector('#btn-menu-open'),
  HMBCLOSE: document.querySelector('#btn-menu-close'),
  SPMENU: document.querySelector('#sp-menu'),
  STICKY: document.querySelectorAll('.sticky'),
  ANIMATIONS: document.querySelectorAll('.js-sc-animation')
}
