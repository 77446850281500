import toBoolean from '../helper/toBoolean'
import { throttle, debounce } from 'throttle-debounce'

export default class Dropdown {
  constructor (el) {
    this.DOM = { el: el }
    this.state = {
      isOpen: false,
      bodyHeight: null
    }

    this.onClick = this.onClick.bind(this)
    this.onResize = this.onResize.bind(this)
  }

  init () {
    if (!this.DOM.el) return
    this.getChildren()
    this.getIsOpen()
    this.getInitialHeight()
    this.bindEvents()

    if (this.state.isOpen) {
      this.showContent()
    }
  }

  getChildren () {
    this.DOM.head = this.DOM.el.querySelector('[data-dropdown="head"]')
    this.DOM.body = this.DOM.el.querySelector('[data-dropdown="body"]')
  }

  getIsOpen () {
    this.state.isOpen = toBoolean(this.DOM.head.dataset.open)
  }

  getInitialHeight () {
    this.state.bodyHeight = this.DOM.body.scrollHeight
  }

  bindEvents () {
    this.DOM.head.addEventListener('click', this.onClick)
    window.addEventListener('resize', throttle(150, this.onResize), false)
  }

  onClick (e) {
    e.preventDefault()
    this.state.isOpen ? this.hideContent() : this.showContent()
  }

  onResize () {
    this.getInitialHeight()
    if (this.state.isOpen) {
      this.DOM.body.style.maxHeight = `${this.state.bodyHeight}px`
    }
  }

  showContent () {
    this.state.isOpen = true
    this.DOM.el.classList.add('is-open')
    this.DOM.body.style.maxHeight = `${this.state.bodyHeight}px`
  }

  hideContent () {
    this.state.isOpen = false
    this.DOM.el.classList.remove('is-open')
    this.DOM.body.style.maxHeight = '0px'
  }
}
