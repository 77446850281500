export default class MatchHeight {
  constructor (el) {
    this.DOM = { el: Array.from(document.querySelectorAll(el)) }
    this.onResize = this.onResize.bind(this)
  }

  init () {
    if (!this.DOM.el) return
    this.bindEvent()
    this.getHeight()
    this.setHeight()
  }

  bindEvent () {
    window.addEventListener('resize', this.onResize)
  }

  getHeight () {
    this.heightList = []
    this.DOM.el.forEach(el => {
      const h = el.clientHeight
      this.heightList.push(h)
    })
    this.maxHeight = Math.max.apply(null, this.heightList)
  }

  setHeight () {
    this.DOM.el.forEach(el => {
      el.style.height = this.maxHeight + 'px'
    })
  }

  resetHeight () {
    this.DOM.el.forEach(el => {
      el.style.height = 'auto'
    })
  }

  onResize () {
    this.resetHeight()
    this.getHeight()
    this.setHeight()
  }
}
