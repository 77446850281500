'use strict'

// babel polyfill
import '@babel/polyfill'
import 'core-js/stable'

// define
import EL from './constant/elements'

// helper
import hmb from './helper/hmb'
import uaDataset from './helper/uaDataset'
import sweetScrollInit from './helper/sweetScrollInit'
import ieSmoothScrollDisable from './helper/ieSmoothScrollDisable'
import getTouchSupport from './helper/getTouchSupport'
import navCurrent from './helper/navCurrent'
import getDocumentH from './helper/getDocumentHeight'
import getOrientation from './helper/getOrientation'
import getClassName from './helper/getClassName'
import navAccordion from './helper/navAccordion'
import addAnimationClass from './helper/addAnimationClass'
import set100vh from './helper/set100vh'
import headerToggle from './helper/headerToggle'
import customSelect from './helper/customSelect'
import inputAutoComplete from './helper/inputAutoComplete'
import headerLeft from './helper/headerLeft'
import getDeviceType from './helper/getDeviceType'

// module
import Dropdown from './modules/Dropdown'
import Strim from './modules/Strim'
import MatchHeight from './modules/MatchHeight'
import GetPdfSize from './modules/GetPdfSize'

// plugins
import objectFitImages from 'object-fit-images'
import picturefill from 'picturefill'
import Stickyfill from 'stickyfilljs'
import { throttle, debounce } from 'throttle-debounce'
import ScrollBooster from 'scrollbooster/dist/scrollbooster.min'
import Swiper from 'swiper/bundle'

// page scripts
import pageNameTop from './page/top'
import pageNameCharter from './page/charter'
import pageNameInquiry from './page/inquiry'
import pageNameBtNagoya from './page/busstop-nagoya'
import pageNameGdpr from './page/gdpr'

// require
require('intersection-observer')
require('focus-visible')

/**
 * getScrollPos
 */
const getScrollPos = () => {
  const y = window.pageYOffset
  const offset = 100
  const copyrightH = EL.COPYRIGHT.clientHeight
  const documentH = getDocumentH()

  // add class is-scroll
  if (y > offset) {
    if (!EL.HTML.classList.contains('is-scroll')) {
      EL.HTML.classList.add('is-scroll')
    }
  } else {
    EL.HTML.classList.remove('is-scroll')
  }

  // add class is-footer
  if (documentH - copyrightH <= y) {
    if (!EL.HTML.classList.contains('is-footer')) {
      EL.HTML.classList.add('is-footer')
    }
  } else {
    EL.HTML.classList.remove('is-footer')
  }
}

/**
 * setDropDown
 */
// const setDropDown = () => {
//   const acordionEl = document.querySelectorAll('[data-accordion="el"]')
//   acordionEl.forEach(
//     el => {
//       const ac = new Dropdown(el)
//       ac.init()
//     }
//   )
// }

/**
 * setTableIconFade
 */
// const setTableIconFade = () => {
//   const scTableEl = document.querySelectorAll('[data-sc-table="el"]')
//   scTableEl.forEach(
//     el => {
//       el.addEventListener('scroll', () => {
//         el.classList.add('is-animation')
//         if (el.scrollLeft > 0) {
//           el.classList.add('is-scroll')
//         } else {
//           el.classList.remove('is-scroll')
//         }
//       })

//       const viewport = el
//       const content = el.querySelector('table')
//       const th = el.querySelector('thead > tr > th')
//       const accentCel = el.querySelector('.c-products-table__accent-cel')

//       const scrollToAccentCel = function () {
//         if (!accentCel || !th) return
//         viewport.scrollLeft = accentCel.offsetLeft - th.clientWidth
//         setTimeout(function () {
//           viewport.classList.remove('is-animation')
//         }, 500)
//       }

//       if (content) {
//         console.log(viewport, content)

//         const viewportWidth = viewport.clientWidth
//         const contentWidth = content.scrollWidth

//         if (viewportWidth < contentWidth) {
//           viewport.classList.add('has-scroll')

//           scrollToAccentCel()
//         }

//         const sb = new ScrollBooster({
//           viewport: viewport,
//           content: content,
//           scrollMode: 'native'
//         })
//       }
//     }
//   )
// }

/**
 * パンくずをスワイプさせる機能を提供します
 */
const breadcrumbScroller = () => {
  const target = document.querySelectorAll('.l-breadcrumb')

  if (target) {
    for (let i = 0; i < target.length; i = (i + 1) | 0) {
      const item = target[i]
      const itemViewport = item.querySelector('.l-breadcrumb__inner')
      const itemContent = item.querySelector('.l-breadcrumb__list')

      const itemViewportWidth = itemViewport.clientWidth
      const itemContentWidth = itemContent.scrollWidth

      if (itemViewport) {
        if (itemViewportWidth < itemContentWidth) {
          itemViewport.classList.add('has-scroll')
        }

        const sb = new ScrollBooster({
          viewport: itemViewport,
          content: itemContent,
          direction: 'horizontal',
          scrollMode: 'native'
        })
      }
    }
  }
}

/**
 * firstRun
 */
const firstRun = () => {
  // set ua dataset
  uaDataset()
  // set touch support dataset
  getTouchSupport()
  // getOrientation
  getOrientation()
  // ie smoothScroll disable
  ieSmoothScrollDisable(true)
  // Polyfill object-fit
  objectFitImages()
  // Polyfill picturefill
  picturefill()
}

/**
 * initRun
 */
const initRun = () => {
  // get body className
  const className = getClassName(EL.BODY)

  // add .is-loaded
  EL.HTML.classList.add('is-loaded')

  // stickyfilljs
  // Stickyfill.add(EL.STICKY)

  // getScrollPos
  getScrollPos()

  // setDropDown
  // setDropDown()

  // setTableIconFade
  // setTableIconFade()

  // navCurrent
  navCurrent(EL.NAV)

  // hmb menu
  hmb()

  // headerLeft
  // headerLeft()

  // customSelect
  customSelect()

  // inputAutoComplete
  inputAutoComplete()

  // headerToggle
  // headerToggle()

  // set100vh
  set100vh()

  // gdpr
  pageNameGdpr()

  // modal
  // modal()

  // navAccordion
  // navAccordion()

  // sweetScroll
  sweetScrollInit()

  // breadcrumbScroller
  breadcrumbScroller()

  // strim
  document.querySelectorAll('[data-strim]').forEach(el => {
    const txt = new Strim(el)
    txt.init()
  })

  // megamenu
  // document.querySelectorAll('[data-megamenu="el"]').forEach(el => {
  //   const megamenu = new Megamenu(el)
  //   megamenu.init()
  // })

  // strim
  //   document.querySelectorAll('[data-modalid]').forEach(el => {
  //      const modal = new Modal(el)
  //      modal.init()
  //  })

  // pdfSize
  // const pdfEl = document.querySelectorAll('[data-pdf]')
  // const getPdf = new GetPdfSize(pdfEl)
  // getPdf.init()

  // set_matchheight
  const mcheight = new MatchHeight('.c-pickup-table__thmbtit')
  mcheight.init()

  // addAnimationClass
  if (EL.ANIMATIONS) {
    addAnimationClass(EL.ANIMATIONS, '-20% 0px')
  }

  // anchorlink hide-header
  const linksToTop = document.querySelectorAll('[data-anchor="to-top"]')
  if (linksToTop) {
    linksToTop.forEach(el => {
      el.addEventListener('click', () => {
        setTimeout(() => {
          EL.HEADER.classList.add('is-animation')
        }, 1000)
      })
    })
  }

  if (className !== undefined) {
    // top
    if (className.endsWith('p-top')) {
      pageNameTop()
    }
    // 貸切バス
    if (className.endsWith('p-charter')) {
      pageNameCharter()
    }
    // バスのりば名古屋
    if (className.endsWith('p-busstop-nagoya')) {
      pageNameBtNagoya()
    }
    // フォーム入力画面
    if (className.endsWith('p-inquiry')) {
      pageNameInquiry()
    }
  }

  // common
  $('#megamenu').on('mouseover', function () {
    $('#header-menu-parent').addClass('is-active')
  })
  $('#megamenu').on('mouseleave', function () {
    $('#header-menu-parent').removeClass('is-active')
  })
  $('#languageBtn').on('click', function () {
    $(this).toggleClass('is-active')
    $('#languageCnt').toggleClass('is-show')
  })

  let flag = true
  $('[data-tabtargetid]').on('click', function () {
    if ($(this).hasClass('is-active')) return
    const el = $(this).parents('[data-tabcontent="el"]')
    const tabs = el.find('[data-tabtargetid]')
    const contents = el.find('[data-tabcontent]')
    const targetId = $(this).data('tabtargetid')
    const target = el.find('[data-tabcontent="' + targetId + '"]')

    if (flag) {
      flag = false
      tabs.removeClass('is-active')
      $(this).addClass('is-active')
      contents.stop().fadeOut(300)
      target.delay(250).fadeIn(300, function () {
        flag = true
      })
    }
  })

  if ($('.js-ac-btn').length) {
    $('.js-ac-btn').on('click', function () {
      const $content = $(this).next('.js-ac-content')
      if (!$content) return
      $(this).toggleClass('is-open')
      $content.slideToggle()
    })
  }

  $('[data-parent-tab-btn]').on('click', function () {
    if ($(this).hasClass('is-active')) return
    const target = $(this).data('parent-tab-btn')
    const $el = $('[data-parent-tab-content="' + target + '"]')
    $('[data-parent-tab-btn]').removeClass('is-active')
    $(this).addClass('is-active')
    $('[data-parent-tab-content]').hide()
    $el.show()
    $el.find('[data-child-tab-btn]').eq(0).trigger('click')
  })

  $('[data-child-tab-btn]').on('click', function () {
    if ($(this).hasClass('is-active')) return
    const target = $(this).data('child-tab-btn')
    const $el = $('[data-child-tab-content="' + target + '"]')
    $('[data-child-tab-btn]').removeClass('is-active')
    $(this).addClass('is-active')
    $('[data-child-tab-content]').hide()
    $el.show()
  })

  $('.js-link-window').on('click', function () {
    window.open($(this).attr('href'), 'map', 'width=700,height=700,scrollbars=yes', '_blank')
    return false
  })

  $('.js-tablewrap').on('scroll', function () {
    let flag = false
    flag = ($(this).scrollLeft() > 10)
    if (flag) {
      $(this).addClass('is-scroll')
    } else {
      $(this).removeClass('is-scroll')
    }
  })
}
/**
 * DOMCONTENTLOADED
 */
window.addEventListener('DOMContentLoaded', firstRun)

/**
 * LOAD
 */
window.addEventListener('load', initRun)

/**
 * SCROLL
 */
window.addEventListener('scroll', throttle(100, getScrollPos), false)
