'use strict'

import EL from '../constant/elements'
import getDeviceType from '../helper/getDeviceType'
import { throttle, debounce } from 'throttle-debounce'

/**
 * 横スクロール時のヘッダーの追従を行います
 */
export default () => {
  /**
   * scroll
   */
  const headerLeft = e => {
    if (window.scrollX > 0) {
      let isActive = false

      if (!isActive) {
        requestAnimationFrame(() => {
          isActive = false

          let ww = window.scrollX
          ww = (window.scrollX < 25) ? 0 : ww
          EL.HEADER.style.left = -ww + 'px'
        })
        isActive = true
      }
    }
  }

  /**
   * control
   */
  const control = {

    lastInnerWidth: window.innerWidth,
    isActive: false,

    /**
     * 初期処理を行います
     */
    init: () => {
      if (getDeviceType() === 'lg') {
        control.isActive = true
        control.lastInnerWidth = window.innerWidth
        control.setup()
      }

      window.addEventListener(
        'resize',
        debounce(150, () => {
          if (control.lastInnerWidth !== window.innerWidth) {
            control.lastInnerWidth = window.innerWidth

            if (getDeviceType() === 'lg') {
              if (!control.isActive) {
                control.init()
              }
            } else {
              if (control.isActive) {
                control.isActive = false
                control.destroy()
              }
            }
          }
        }),
        false
      )
    },
    setup: () => {
      document.addEventListener('scroll', headerLeft, { passive: true })
    },
    destroy: () => {
      document.removeEventListener('scroll', headerLeft, { passive: true })
    }
  }

  control.init()
}
