'use strict'
export default () => {

  var cookie = document.cookie;
  var gdprArea = document.getElementById("gdprArea"); 
  var gdprClose = document.getElementById("gdprClose");
  var pageTop = document.getElementById("pagetop-btn");
  var width = window.innerWidth;
  var breakPointSp = 768;

  if (!cookie) {
    gdprAreaSet();
    gdprArea.classList.add("open");
  } else {
    gdprArea.classList.remove("open");
  }

    gdprClose.addEventListener("click", function() {

      document.cookie = 'gdpr=close';

      gdprArea.classList.remove("open");
  
      if (width < breakPointSp) {
        pageTop.style.bottom = "50px";
      } else {
        pageTop.style.bottom = "5%";
      }
  
    });


  function gdprAreaSet() {

    window.addEventListener( "resize", function () {
        heightSet();
    } ) ;
    

    window.addEventListener("scroll", function(){
        heightSet();
    });

    function heightSet() {
      
      var winH = window.innerHeight;
      var docH = document.body.clientHeight;
      var footerH = document.querySelector("footer").clientHeight;
      var pageBottom = docH - (winH + footerH);
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;

      if (pageBottom +50 <= scrollTop ) {

        gdprArea.style.bottom = "auto"; 
        gdprArea.style.top = "0"; 
        pageTop.classList.add("down");

      } else {

        gdprArea.style.bottom = "0"; 
        gdprArea.style.top = "auto"; 
        pageTop.classList.remove("down");

      }

    }

  }

}
