'use strict'
export default () => {
  const toggleFormBody = () => {
    if (!$('#checkPersonalInfo').length || !$('#formBody').length) return
    if ($('#checkPersonalInfo').prop('checked')) {
      $('#formBody').show()
    } else {
      $('#formBody').hide()
    }
  }
  const toggleConfirmBox = () => {
    if (!$('.js-input-type').length || !$('#confirmBox').length || !$('#checkboxConfirm').length) return
    if ($('.js-input-type:checked').val() !== '1') {
      $('#confirmBox').hide()
      $('#checkboxConfirm').prop('checked', false)
    } else {
      $('#confirmBox').show()
    }
  }

  const toggleSubmitBtn = () => {
    if (!$('#submitBtn').length || !$('.js-input-type').length) return
    if ($('.js-input-type:checked').val() === '1' && !$('#checkboxConfirm').prop('checked')) {
      $('#submitBtn').prop('disabled', true)
    } else {
      $('#submitBtn').prop('disabled', false)
    }
  }

  toggleFormBody()
  toggleConfirmBox()
  toggleSubmitBtn()

  $('.js-input-type').on('change', () => {
    toggleConfirmBox()
    toggleSubmitBtn()
  })

  $('#checkboxConfirm').on('change', () => {
    toggleSubmitBtn()
  })

  $('#resetBtn').on('click', () => {
    setTimeout(toggleConfirmBox, 10)
    toggleSubmitBtn()
  })

  $('#checkPersonalInfo').on('change', () => {
    toggleFormBody()
  })
}
