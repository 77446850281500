import jQuery from 'jquery'

export default class GetPdfSize {
  constructor (el) {
    this.DOM = { el: el }
  }

  init () {
    if (!this.DOM.el) return
    this.setFileSize()
  }

  setFileSize () {
    Array.prototype.forEach.call(this.DOM.el, (el) => {
      const filePath = el.dataset.pdf
      const f = jQuery.ajax({
        type: 'HEAD',
        url: filePath,
        dataType: 'text'
      }).done(() => {
        el.innerHTML = this.getByte(f.getResponseHeader('Content-Length'))
      })
    })
  }

  getByte (num) {
    if (num < 1000000) {
      const n = (Math.round((num) / 1000))
      return n + 'KB'
    } else {
      const n = (Math.round((num * 10) / 1000000)) / 10
      return n + 'MB'
    }
  }
}
