export default class Strim {
  constructor (el) {
    this.DOM = { el: el }
    this.resultLen = 40
    this.Text = ''
  }

  init () {
    if (!this.DOM.el) return
    this.Text = this.getText()
    this.resultLen = this.getLength()
    this.DOM.el.innerHTML = this.getResultText()
  }

  getText () {
    return this.DOM.el.innerHTML
  }

  getLength () {
    if (!this.DOM.el.dataset.strim) return this.resultLen
    return this.DOM.el.dataset.strim
  }

  getResultText () {
    if (this.Text.length <= this.resultLen) {
      return this.Text
    } else {
      return this.Text.substr(0, this.resultLen) + '...'
    }
  }
}
