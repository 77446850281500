'use strict'
import getDeviceType from '../helper/getDeviceType'
import Swiper from 'swiper/bundle'

export default () => {
  let deviceType = getDeviceType()
  let topMvSlider
  let initMvSlider
  if ( $(".top-mv-slider .swiper-slide").length > 1 ) {
    initMvSlider = () => {
      topMvSlider = new Swiper('.top-mv-slider', {
        init: true,
        slidesPerView: 1,
        spaceBetween: 0,
        centeredSlides: true,
        autoplay: {
          delay: 6000
        },
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        breakpoints: {
          768: {
            slidesPerView: 'auto',
            spaceBetween: 70
          }
        },
        on: {
          init: () => {
            $('#top-mv-slider').addClass('is-loaded')
          }
        }
      })
    }
  }

  initMvSlider()

  $(window).on('resize', () => {
    if (deviceType !== getDeviceType()) {
      deviceType = getDeviceType()
      topMvSlider.destroy(true, true)
      initMvSlider()
    }
  })

  const topInfoSlider = new Swiper('.top-info-slider', {
    init: true,
    slidesPerView: 'auto',
    spaceBetween: 20,
    centeredSlides: true,
    autoplay: {
      delay: 5000
    },
    loop: true,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    breakpoints: {
      768: {
        slidesPerView: 4,
        spaceBetween: 20,
        centeredSlides: false
      }
    },
    on: {
      init: () => {
        $('.top-info-slider').addClass('is-loaded')
      }
    }
  })
}
