'use strict'
import getDeviceType from '../helper/getDeviceType'
import Swiper from 'swiper/bundle'

export default () => {
  let deviceType = getDeviceType()
  let btNagoyaSlider

  const initCharterSlider = () => {
    btNagoyaSlider = new Swiper('.js-btNagoya-slider', {
      init: true,
      slidesPerView: 1,
      spaceBetween: 0,
      centeredSlides: true,
      observer: true,
      observeParents: true,
      autoplay: {
        delay: 6000
      },
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }
    })
  }

  initCharterSlider()

  $(window).on('resize', () => {
    if (deviceType !== getDeviceType()) {
      deviceType = getDeviceType()
      btNagoyaSlider.destroy(true, true)
      initCharterSlider()
    }
  })
}
